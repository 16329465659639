import React from "react";
import "../styles/FollowUs.css";
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaPinterest,
} from "react-icons/fa"; // Importing social media icons
import InstaCode from "../assets/images/followUs/Insta_code.jpg";
import { MdEmail } from "react-icons/md"; //Import email icon
import { IoMdCall } from "react-icons/io"; //Import Call Icon

const FollowUs = () => {
  return (
    <div className="follow-us-container">
      <div className="upper-container row-container align-center">
        <div className="main-heading-container">
          <div className="heading">Princess Lawn</div>
          <div className="small-heading">Plan your wedding with Us</div>
          <div className="text">
            The ideal venue for dream events of any kind! This is a once in a
            lifetime event and you need to make it yours!"
          </div>
        </div>
      </div>
      <div className="lower-container">
        <div className="follow-left-section">
          <div className="follow-contact-section">
            <div className="font-weight-600 font-size-18">
              Contact us to get best deals
            </div>
            <div className="margin-top-10 margin-bottom-20">
              <MdEmail /> princesslawn0109@gmail.com <br />
              <div className="margin-top-10">
                <IoMdCall /> +91-9819995589 / +91-8446055589
              </div>
              <div className="margin-top-10">
                <IoMdCall /> +91-8446555899 / 0712-6420642
              </div>
            </div>
          </div>
          <div className="address-section">
            <div className="font-weight-600 font-size-18">
              Registered Address
            </div>
            <div className="font-weight-400 font-size-15 margin-top-10">
              Narasala Village(Dighori), Umred Road, <br></br>Nagpur - 440034
            </div>
          </div>
        </div>
        <div className="follow-right-section">
          {/*  */}

          <div className="social-icons">
          <div className="font-size-15 font-weight-600 margin-bottom-10">
            Follow Us On
          </div>
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook className="icon facebook" />
              <span className="followus-fa-span">Facebook</span>
            </a>
            <a
              href="https://www.instagram.com/princess_lawns123?igsh=MXdreG5idm03M2h2OQ=="
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram className="icon instagram" />
              <span className="followus-fa-span">Instagram</span>
            </a>
            {/* <img src={InstaCode} alt={"InstaCode"} /> */}
            {/* <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter className="icon twitter" />
              <span className="followus-fa-span">twitter</span>
            </a> */}
            <a
              href="https://pinterest.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaPinterest className="icon pinterest" />
              <span className="followus-fa-span">Pinterest</span>
            </a>
          </div>
          <div className="follow-InstaCode">
                <img src={InstaCode} alt={"InstaCode"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FollowUs;
