import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "../styles/Navbar.css";
import logo from "../assets/images/logo/logo.jpg";
import { Menu, X } from "lucide-react";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navRef = useRef(null);

  // Handle click outside to close menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

   // Close the menu when a menu item is clicked
   const handleMenuItemClick = () => {
    if (window.innerWidth <= 768) {
      setIsMenuOpen(false); // Close menu on mobile
    }
  };
  return (
    <nav
      ref={navRef}
      className={`nav-main-container ${isMenuOpen ? "mobile-menu-open" : ""}`}
    >
      <div className="nav-main-container">
        <div className="nav-wrapper">
          <Link to="/" className="brand-logo">
            <img src={logo} alt="princesslawn" />
          </Link>
          <button
            className={`mobile-menu-btn ${isMenuOpen ? "active" : ""}`}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label="Toggle menu"
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
          <ul id="nav-mobile" className={isMenuOpen ? "active" : ""}>
          <li><Link to="/" onClick={handleMenuItemClick}>Home</Link></li>
          <li><Link to="/gallery" onClick={handleMenuItemClick}>Gallery</Link></li>
          <li><Link to="/aboutus" onClick={handleMenuItemClick}>About Us</Link></li>
          <li><Link to="/contactus" onClick={handleMenuItemClick}>Contact Us</Link></li> 
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
