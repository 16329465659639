import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  BrowserRouter,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import FollowUs from "./components/FollowUs";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Gallery from "./components/Gallery";

import AnimatedSwitch from "./components/AnimatedSwitch";
import LoadingScreen from "./components/LoadingScreen";
import ContactUs from "./components/ContactUs";
import AboutUs from "./components/AboutUs";
import { SpeedInsights } from '@vercel/speed-insights/react';
import { Analytics } from '@vercel/analytics/react';

import "./styles/App.css";

function App() {
  console.log("In App page");
  return (
    <BrowserRouter>
      <div className="App">
      <Analytics />
      <SpeedInsights />
      
        <LoadingScreen>
         
        <Navbar />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/contactus" exact element={<ContactUs />} />
          <Route path="/aboutus" exact element={<AboutUs />} />
          <Route path="/gallery" exact element={<Gallery />} />
        </Routes>
        <div>
          <FollowUs />
        </div>
        <div>
          <Footer />
        </div>
        </LoadingScreen>
      </div>
    </BrowserRouter>
  );
}

export default App;
