import entrance from "../assets/images/gallery/GrandEntry/Entrance.JPG";
import haldi from "../assets/images/gallery/haldi.JPG";

import food from "../assets/images/gallery/food_counter.JPG";
import requirements from "../assets/images/contactus/requirements.jpg";
import proposal from "../assets/images/contactus/proposal.jpg";
import finalize from "../assets/images/contactus/finalize.jpg";
import collage from "../assets/images/contactus/collage.jpeg";
import collage_2 from "../assets/images/contactus/collage_2.jpg";
import lawn from "../assets/images/ventures/DSC08182.JPG";



export const inHouseServiceImages = [entrance,haldi,food]

export const contactUsImages = [collage_2,requirements,proposal,finalize]
export const princess_ventures =[
{
    name:"Princess Lawns",
    backgroundImage:lawn,
},
{
name:"Princess Caterers",
backgroundImage:food,
},
{
    name:"Princess Decorators",
    backgroundImage:entrance,
}
]

