import React from "react";
import { useRef, useState, useEffect } from "react";
import { Img } from "react-image"; // Import optimized image component
import { Link } from "react-router-dom";
import "../styles/AboutUs.css";
import bc_image from "../assets/images/aboutus/banner.jpg";
import { motion } from "framer-motion";
import { princess_ventures } from "../utilities/images.js";
import { aboutus_data, values } from "../utilities/data.js";
import Team from "./Team"; // Adjust the path if needed
import LoadingScreen from "./LoadingScreen";

const displayOtherVentures = () => (
  <div>
    <h2>Other Ventures</h2>
    <motion.div className="carousel" whileTap={{ cursor: "grabbing" }}>
      <motion.div className="inner-div">
        {princess_ventures.map(({ backgroundImage, name }) => {
          return (
            <motion.div className="venture-item" key={name}>
              <img src={backgroundImage} atl="image" />
              <div className="venture-name">{name}</div>
            </motion.div>
          );
        })}
      </motion.div>
    </motion.div>
  </div>
);
const OurValues = () => {
  const [hoveredValue, setHoveredValue] = useState(null);
  return (
    <div className="ourValues-container">
      <div className="left-container-aboutus">
        <h2>Our Values</h2>
        <p>
          We take pride in providing the best to our stakeholders. 
        </p>
        <p>These  Principles, define and direct Princess Lawn to create a better future.</p>
      </div>
      <div className="right-container-aboutus">
        {values.map(({ name, value, icon: Icon }) => {
          return (
            <div
              className={`value-item ${hoveredValue === name ? "hovered" : ""}`}
              key={name}
              onMouseEnter={() => setHoveredValue(name)}
              onMouseLeave={() => setHoveredValue(null)}
            >
              <div className="value-header">
                <span className="value-icon">
                  <Icon size={24} color="#5d0c0d" />
                </span>
                
                <h4>{name}</h4>
              </div>

              {hoveredValue === name && (
                <div className="value-info">{value}</div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const AboutUs = () => {
  const [hoveredValue, setHoveredValue] = useState(null);
  const carousel = useRef();

  return (
    <LoadingScreen>
    <div className="about-us">
      {/* <div className="header-aboutus">
        <h1>About Us</h1>
      </div> */}
      <div className="aboutus-bcimage">
        <img src={bc_image} atl="image" />
      </div>
      <div className="content">
        <div className="header-data">
          <h2>
            13 years <br></br>of experience{" "}
          </h2>
          <p>{aboutus_data["header"]}</p>
          <br />

          <Link to="/">
            <button className="watch-video-btn">Watch Video</button>
          </Link>
        </div>
        <div className="info">
          <p>{aboutus_data["para"]}</p>
          <p>{aboutus_data["para2"]}</p>
          <p>{aboutus_data["para3"]}</p>
        </div>
      </div>
      <div className="other-ventures">{displayOtherVentures()}</div>
      <div className="ourValues-container">
        <OurValues />
      </div>
      {/* <div className="team-container">
        <Team />
      </div> */}
    </div>
    </LoadingScreen>
  );
};

export default AboutUs;
