import React, { useState } from "react";
import "../styles/ContactUs.css";
import { FaUser } from "react-icons/fa";
import { TiMessages } from "react-icons/ti";
import { MdEmail } from "react-icons/md";
import { FaMobileAlt } from "react-icons/fa"; //Import email icon
import { contactUsImages } from "../utilities/images.js";
import { FaHandPointRight } from "react-icons/fa";
import videoBg from "../assets/videos/C9420.MP4";
import LoadingScreen from "./LoadingScreen";
import SuccessModal from "./SuccessModel";

const ContactUs = () => {

  const [values, setValues] = useState({
    userName: "",
    mobileNumber: "",
    formData: new FormData()
})

  const { userName, mobileNumber, formData, } = values;
  // Add state for modal
  const [showModal, setShowModal] = useState(false);
  const handleChange =(name) => (e) => {
    let value = e.target.value;
   // Validation logic
   if (name === "mobileNumber") {
    // Remove any non-digit characters
    value = value.replace(/\D/g, '');
    
    // Limit to 10 digits
    if (value.length > 10) {
      value = value.slice(0, 10);
    }
    
    // Validate mobile number format
    if (value.length === 10 && !/^[6-9]\d{9}$/.test(value)) {
      setValues({
        ...values,
        error: "Please enter a valid Indian mobile number"
      });
    } else {
      setValues({
        ...values,
        error: ""
      });
    }
  }

  if (name === "userName") {
    // Remove numbers and special characters
    value = value.replace(/[^a-zA-Z\s]/g, '');
    
    // Validate name length
    if (value.length > 50) {
      value = value.slice(0, 50);
    }
  }

  // Update FormData
  //formData.set(name, value);

  // Update state
  setValues({
    ...values,
    [name]: value,
    error: ""
  });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Validation before submit
    if (mobileNumber.length !== 10) {
      setValues({
        ...values,
        error: "Please enter a valid 10-digit mobile number"
      });
      return;
    }

    if (userName.trim().length < 2) {
      setValues({
        ...values,
        error: "Please enter a valid name"
      });
      return;
    }
    // Here you can add the logic to send form data to your server
    //console.log("Form data submitted:", Object.fromEntries(formData));
    const timestamp = new Date().getTime();
     // Create URL with parameters
     const url = `https://script.google.com/macros/s/AKfycbyXSwxqm1cSSAgddmp1re_Sc0LKYfOVHEt3vk_V2gWcAdgnz9i-iXPKWK41BgciFiUWGA/exec?userName=${encodeURIComponent(userName)}&mobileNumber=${encodeURIComponent(mobileNumber)}&timestamp=${timestamp}`;
     // Show the success modal
     setShowModal(true);
     fetch(url, {
       method: 'GET',
       mode: 'no-cors',
     })
     .then(() => {
       // Reset form and show success message
       setValues({
         userName: "",
         mobileNumber: "",

       });
        
     })
     .catch(error => {
       console.error('Error:', error);
       setValues({
         ...values,
         //formStatus: "Failed to submit. Please try again.",
         //error: ""
       });
     });
    
    
  };
  const contactusForm = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">
            <FaUser />
            &nbsp; Name:
          </label>
          <input
            type="text"
            id="userName"          
            value={userName}
            onChange={handleChange("userName")}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="Mob. No.">
            <FaMobileAlt />
            &nbsp;Mob. No.:
          </label>
          <input
            type=""
            id="number"
            value={mobileNumber}
            onChange={handleChange("mobileNumber")}
            placeholder="Enter 10 digit mobile number"
            required
          />
        </div>

        <button type="submit">Get Wedding Proposal</button>
      </form>
      // {formStatus && <p className="form-status">{formStatus}</p>}
    );
  };

  const upperSection = () => {
    return (
      <div className="contact-upperContainer">
        <div className="contact-leftContainer">
          {" "}
          {/* {console.log(contactUsImages)} */}
          <img src={contactUsImages[0]} atl="image" />
        </div>
        <div className="contact-rightContainer">
          <h2>Destination Wedding in Nagpur, in Your Budget</h2>
          <div className="contact-us">{contactusForm()}</div>
        </div>
      </div>
    );
  };

  const midSection = () => {
    return (
      <div className="contact-midContainer">
        <div className="contact-howItWorks">
          <img src={contactUsImages[1]} atl="requirements" />
          <h2>Share your requirements</h2>
        </div>
        <FaHandPointRight className="icon-margin" size={40} color="#5d0c0d" />
        <div className="contact-howItWorks">
          <img src={contactUsImages[2]} atl="proposal" />
          <h2>Get Personalized Proposal</h2>
        </div>
        <FaHandPointRight className="icon-margin" size={40} color="#5d0c0d" />
        <div className="contact-howItWorks">
          <img src={contactUsImages[3]} atl="finalize" />
          <h2>Finalize & save huge</h2>
        </div>
      </div>
    );
  };

  const VideoPlayer = () => {
    return <video src={videoBg} autoPlay muted loop></video>;
  };

  const lowerSection = () => {
    return (
      <div className="contact-lowerContainer">
        <div className="contact-leftContainer">
          <div className="contact-content">
            <h2>Dedicated Wedding Planners</h2>
            Princess Lawn has trusted team of professionals, working
            round-the-clock to make your wedding dream come true
          </div>
        </div>
        <div className="contact-rightContainer">
          <div className="contact-video">
            <VideoPlayer />
          </div>
        </div>
      </div>
    );
  };

  return (
    <LoadingScreen>
       <SuccessModal 
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        message="Thank you! We'll contact you soon."
      />
    <div className="contact-us-main-container">
      <div className="contact-us-container">
        {upperSection()}
        <h2>How it Works</h2>
        <h4>Premium Weddings done in 3 easy steps</h4>
        {midSection()}
        {lowerSection()}
      </div>
    </div>
   
    </LoadingScreen>
  );

 
};

export default ContactUs;
