import React, { useState, useEffect } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import "../styles/LoadingScreen.css";

const LoadingScreen = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handlePageLoaded = () => {
      setIsLoading(false);
    };

    // Check if all resources are already loaded
    if (document.readyState === "complete") {
      handlePageLoaded();
    } else {
      window.addEventListener("load", handlePageLoaded);
    }

    // Prevent body scroll during loading
    if (isLoading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }

    return () => {
      window.removeEventListener("load", handlePageLoaded);
      document.body.style.overflow = "visible";
    };
  }, [isLoading]);

  return (
    <>
      {isLoading && (
        <div className="loading-screen">
          <div className="loader-content">
            <PuffLoader
              color="#ff0000"
              size={120}
              speedMultiplier={1}
              cssOverride={{
                filter: "drop-shadow(0 0 12px rgba(255, 0, 0, 0.5))",
              }}
            />
            <p className="loader-text">Loading...</p>
          </div>
        </div>
      )}
      <main
        className={`transition-opacity duration-700 ${
          isLoading ? "opacity-0" : "opacity-100"
        }`}
      >
        {children}
      </main>
    </>
  );
};

export default LoadingScreen;
