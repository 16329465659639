import React, { useRef, useState, useEffect } from "react";
import { motion, useScroll } from "framer-motion";
import { Quote, Star } from "lucide-react";
import testimonials from "../utilities/testimonials";
import "../styles/Testimonials.css";

const Testimonials = () => {
  const ref = useRef(null);
  const { scrollXProgress } = useScroll({ container: ref });
  const carousel = useRef();
  const [width, setWidth] = useState(0);
  useEffect(() => {
    setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
  }, []);

  const renderStars = (rating) => {
    return Array.from({ length: 5 }, (_, index) => (
      <Star
        key={index}
        className={`star ${index < rating ? "filled" : "empty"}`}
        fill={index < rating ? "#fbbf24" : "none"}
      />
    ));
  };

  return (
    <div className="testimonial-main-container">
      <div className="gallery-section">
        <motion.div
          ref={carousel}
          className="carousel"
          whileTap={{ cursor: "grabbing" }}
        >
          <motion.div
            drag="x"
            dragConstraints={{ right: 0, left: -width }}
            className="inner-carousel"
          >
            {testimonials.map((testimonial, index) => (
              <motion.div
                key={testimonial.id}
                dragConstraints={{ right: 0, left: -width }}
                initial={{ opacity: 0, scale: 0.9 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 100,
                  delay: index * 0.2,
                }}
                viewport={{ once: true }}
                className="testimonial-card"
              >
                <Quote className="quote-icon" />

                <div className="testimonial-content">
                  <h3 className="testimonial-name">{testimonial.name}</h3>
                  <p className="testimonial-quote">"{testimonial.quote}"</p>
                  <div className="testimonial-footer">
                    <p className="testimonial-position">
                      {testimonial.position}
                    </p>
                    <div className="testimonial-rating">
                      {renderStars(testimonial.rating)}
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};


export default Testimonials;
