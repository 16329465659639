import React from "react";
import "../styles/Footer.css";

const Footer = () => {
  return (
    <div className="footer-content">
      <div className="trademark">
        <div className="left-container">
          <div className="margin-left-10">&copy; 2024 PrincesLawns</div>
        </div>
        <div className="right-container">
          <h4>Terms & Conditions</h4>&nbsp;|&nbsp; <h4>Privacy Policy</h4>
        </div>
        <span></span>
      </div>
    </div>
  );
};

export default Footer;
