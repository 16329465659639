import React, { useState }  from "react";
import FollowUs from "./FollowUs";
import Footer from "./Footer";
import "../styles/Home.css";

import InhouseServices from "./InhouseServices";
import Testimonials from "./Testimonials";
import WeddingPackagesCard from "./WeddingPackagesCard";
import TestimonialHeader from "./TestimonialHeader";
import VideoPlayer from "./VideoPlayer";
import { PuffLoader } from "react-spinners";

const Home = () => {
 

  
  return (
    <div className="home">
       <div className="homeContainer">
        <VideoPlayer />
      </div>

      <div className="inhouse-service-container">
        <h2 className="gallery-title">Inhouse Services</h2>
        <InhouseServices />
        {/* <ZoomInCard /> */}
      </div>

      <div>
        <WeddingPackagesCard />
      </div>

      {/* <Reviews /> */}

      <TestimonialHeader />
      <Testimonials />
    </div>
  );
};

export default Home;
