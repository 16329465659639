import React, { useState } from "react";
import Card, {
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
  CardFooter,
} from "../components/Card";
import { Heart, Camera } from "lucide-react";
import { weddingPackages } from "../utilities/weddingPackagesData.js"; // Import your data
import "../styles/WeddingPackages.css";
import logo from "../assets/images/logo/logo.png";
const WeddingPackagesCard = () => {
  const [hoveredPackage, setHoveredPackage] = useState(null);

  return (
    <div className="wedding-packages-container">
      <div className="wedding-content">
        <h2 className="wedding-packages-title">
          <Camera className="icon" size={30} />
          Princess Lawns Wedding Packages
          <Camera className="icon" size={30} />
        </h2>

        <div className="wedding-packages-grid">
          {weddingPackages.map((pkg, index) => (
            <Card
              key={index}
              className="wedding-package-card"
              onMouseEnter={() => setHoveredPackage(index)}
              onMouseLeave={() => setHoveredPackage(null)}
            >
              <CardHeader>
                <img src={pkg.bannerImage} alt={pkg.title} />
              </CardHeader>
              <CardContent>
                <CardTitle>{pkg.title}</CardTitle>
                <CardDescription>{pkg.description}</CardDescription>

                <div className="features">
                  {pkg.features.map((feature, featureIndex) => (
                    <div key={featureIndex} className="feature">
                      <Camera className="icon" size={12} /> {feature}
                    </div>
                  ))}
                </div>
              </CardContent>
              <CardFooter>
                <div className="footer-contents">
                  <div className="price">
                    {pkg.price}
                    <span className="price-label">Starting Price</span>
                  </div>
                  <button className="book-button">
                    <Heart className="icon" size={20} />
                    Book Now
                  </button>
                </div>
              </CardFooter>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WeddingPackagesCard;
