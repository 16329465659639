import React from "react";
import "../../styles/Gallery.css"; // Link to custom CSS file
import { gallerySectionVideo } from "../../utilities/galleryData.js"; // Import data for gallery
import { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";

const VideoGallery = () => {
  const [width, setWidth] = useState(0);
  const carousel = useRef();
  const [selectedImage, setSelectedImage] = useState(null);
  useEffect(() => {
    setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
  }, []);
  const openModal = (image) => setSelectedImage(image);
  const closeModal = () => setSelectedImage(null);
  return (
    <>
      {gallerySectionVideo.map(({ sectionTitle, images }, index) => (
        <div className="gallery-section" key={index}>
          <h2 className="gallery-title">{sectionTitle}</h2>
          <motion.div
            ref={carousel}
            className="carousel"
            whileTap={{ cursor: "grabbing" }}
          >
            <motion.div
              drag="x"
              dragConstraints={{ right: 0, left: -width }}
              className="inner-carousel"
            >
              {images.map((image, idx) => (
                <motion.div className="item-data" key={image}>
                  <div
                    className="image-card"
                    key={image}
                    onClick={() => openModal(image)}
                  >
                   
                    
                    <video src={image}
                     autoPlay 
                     muted 
                     loop 
                    playsInline
                    controls={false}></video>;
                  </div>
                </motion.div>
              ))}
            </motion.div>
          </motion.div>
        </div>
      ))}
      {/* Image Modal */}
      {selectedImage && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content">
            <img
              src={selectedImage}
              alt="Selected"
              className="modal-image"
              onClick={(e) => e.stopPropagation()} // Prevent modal close on image click
            />
          </div>
        </div>
      )}
    </>
  );
};

export default VideoGallery;
