import { Heart,  Star, Award } from 'lucide-react';

import silver_Package from "../assets/images/WeddingPackages/silverPackage.jpg";
import golden_Package from "../assets/images/WeddingPackages/goldenPackage.jpg";
import platinum_Package from "../assets/images/WeddingPackages/platinumPackage.jpg";
export const weddingPackages = [
    {
      icon: <Award size={40} color="#5d0c0d" />,
      title: 'Silver Celebration Package',
      description: 'Intimate wedding for 1000 guests',
      features: [
        '17 food items served for 1000 guests',
        'Elegant main stage decoration & seating for 200 guests',
        '2 dressing rooms & 10 round tables with decor',
        'Service staff & 2 female greeters at the main gate',
        'Instrumental music & electricity backup'
      ],
      price: '₹2,75,000',
      bannerImage: silver_Package
    },
    {
      icon: <Star size={40} color="#5d0c0d" />,
      title: 'Gold Celebration Package',
      description: 'Grand wedding for 1000 guests',
      features: [
        'Four elegant main stage decor options',
        '34 food items served for 1000 guests',
        'Seating for 200 guests',
        '2 dressing rooms & 10 round tables with decor',
        'Service staff & 2 female greeters at the main gate',
        'Instrumental music & electricity backup'
      ],
      price: '₹4,00,000',
      bannerImage: golden_Package
    },
    {
      icon: <Heart size={40} color="#5d0c0d" />,
      title: 'Platinum Celebration Package',
      description: 'Exclusive wedding for 1000 guests',
      features: [
        'Signature main stage, canopy, & arch decor options.',
        '50 food items served for 1000 guests',
        'Seating for 200 guests',
        '2 dressing rooms & 10 round tables with decor',
        '4 male staff and 2 female greeters at the stage.',
        'Instrumental music & electricity backup'
      ],
      price: '₹5,50,000',
      bannerImage: platinum_Package
    }
  ];