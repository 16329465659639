
import React, { useState } from 'react';
import videoBg from "../assets/videos/C9420.MP4";
import "../styles/VideoPlayer.css"; // Ensure you create and link this CSS file

const VideoPlayer = () => {
    const [isLoading, setIsLoading] = useState(true);
    return(
        <div className="video-container">
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
            <div className="w-12 h-12 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
          </div>
        )}
        <video
          src={videoBg}
          autoPlay
          muted
          loop
          className="video-bg"
          playsInline  // Ensures video plays inline on mobile devices
          controls={false}  // Remove controls for automatic play
          onLoadedData={() => setIsLoading(false)}
        ></video>
      </div>
    )
   
};

export default VideoPlayer;
