import React from "react";
import { Star } from "lucide-react";
import "../styles/TestimonialHeader.css";

const TestimonialHeader = () => {
  return (
    <div className="testimonial-header-container">
      <div className="testimonial-header">
        <div className="testimonialHeader-content ">
          <span className="label">What People Say</span>
          <h2 className="title">Client Testimonials</h2>
          <div className="subtitle-container">
            <p className="subtitle">
              Discover why our clients love working with us. Read their success
              stories and experiences.
            </p>
          </div>

          <div className="decorative-bars">
            <div className="bar bar-long"></div>
            <div className="bar bar-short"></div>
            <div className="bar bar-short"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialHeader;
