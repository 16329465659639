import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import '../styles/SuccessModal.css';

const SuccessModal = ({ isOpen, onClose, message }) => {
    if (!isOpen) return null;
  
    return (
      <div className="modal-overlay">
        <div className="modal-card">
          <div className="modal-content">
            {/* Success Icon */}
            <div className="icon-container">
              <svg className="success-icon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
            </div>
  
            {/* Message */}
            <p className="modal-message">{message}</p>
  
            {/* Close Button */}
            <button
              onClick={onClose}
              className="close-button"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  };
  export default SuccessModal;