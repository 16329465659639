import { HiUsers } from "react-icons/hi";
import { FaSmile, FaBroom, FaLightbulb } from "react-icons/fa"; // Import icons from react-icons
import { AiFillStar } from "react-icons/ai";
import { RiShieldCheckFill } from "react-icons/ri";
import image_sakshi from "../assets/images/team/Sakshi.JPEG";
import image_kaniksha from "../assets/images/team/kaniksha.jpeg";
import image_rajkumari from "../assets/images/team/Rajkumari.jpeg";

export const values=[{
        name:"Customer Satisfaction:",
        value:`Creating unforgettable memories and
          ensuring that your wedding experience exceeds expectations.`,
          icon:FaSmile
},{
        name:"Cleanliness:",
        value:`Providing a pristine and hygienic environment to make
          your wedding day flawless and beautiful.`,
          icon:HiUsers    
},{
        name:"Innovation:",
        value:`Offering unique and creative wedding setups that are
          tailored to your personal style and preferences.`,
          icon:FaLightbulb        
},{
        name:"Quality:",
        value:`We pride ourselves on delivering high-quality service,
          attention to detail, and an elegant atmosphere for your special day.`,
          icon:AiFillStar   
},{
        name:"Integrity:",
        value:`Operating with transparency, honesty, and dedication,
          ensuring that your wedding plans go smoothly without any surprises.`,
          icon:RiShieldCheckFill     
}]

export const aboutus_data = {
    header:` Princess Lawn is Nagpur's premier wedding destination, offering an unparalleled experience for over 13 years. With a focus on providing the best in-house services, from stunning decorations to exquisite food, 
    we ensure every wedding is a memorable celebration. Our customer-centric approach, grounded in strong values, ensures that every event is executed with perfection. At Princess Lawn, we prioritize excellence, making every celebration truly special.`,
    para:` Princess Lawn is the perfect venue for your dream events! Spanning over 30,000 sq. ft. of beautifully landscaped grounds, 
    it’s adorned with majestic palm trees, illuminated chandelier pathways, and picturesque stone gazebos.`,
    para2:`Enchanting lighting creates an atmosphere of romance and mystique, offering a breathtaking view high above the city of Nagpur. With a seating capacity of 400 people and space for over 2,000 guests,
     Princess Lawn also features 2 well-furnished A/C dressing rooms, spacious buffet areas, 6 extra rooms, and an accommodation hall that can host up to 100 guests.`,
    para3:`Whether it’s a wedding, engagement, birthday party, naming ceremony, sangeet, mehendi, or corporate events like product launches, business conferences, exhibitions, or cultural celebrations such as Sankrant, Dandiya Night, or Diwali parties, we provide the perfect backdrop for unforgettable moments. 
    Our mission is to create a truly special setting and offer flawless service for you and your guests. We would be delighted to meet with you and discuss how we can make your upcoming event extraordinary.`
}

export const teamMembers = [
        {
          name: "RAJKUMARI BAMBODE",
          designation: "Founder & CEO",
          image: image_rajkumari, // Replace with actual image path
        },
        {
          name: "KANISHKA BAMBODE",
          designation: "Co-Founder & COO",
          image: image_kaniksha, // Replace with actual image path
        },
        {
          name: "SAKSHI D.",
          designation: "Creative Head",
          image: image_sakshi, // Replace with actual image path
        },
      ];
      

