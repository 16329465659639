const testimonials = [
    {
      id: 1,
      name: "Ravi Prasad",
      quote:
        `A wonderful venue for weddings and parties, nice building planning,
            garden is nice. I liked the staffs work and there service was good.
            The food was great and last i felt there price for all of this is
            pretty decent.`,
      rating: 5,
      image: "",
    },
    {
      id: 2,
      name: "Avinash Jadhav",
     
      quote:
        `Spacious lawn with great arrangement for Music System. The stage area is also quite large and neat. 
        The lawn in maintained properly and it also houses few rooms for accommodation. One the best lawn of Nagpur.`,
      rating: 5,
      image: "",
    },
    {
      id: 3,
      name: "Sachin Borghare",
      quote:
        `Nagpur's one of the finest lawn with all facilities including superior ambience. Very lavish and high profile party lawn. 
        Ample space and all arrangements are very good. Its a good place to have a party or any other celebration.`,
      rating: 4,
      image: "/api/placeholder/150/150",
    },
    {
      id: 4,
      name: "Prasad Awandkar",
      quote:
        `Princess Lawn made our wedding day truly special. The beautiful setting, excellent in-house services, and attentive staff ensured everything went smoothly. 
        With spacious lawns, stunning decor, and delicious food, it was the perfect venue for our celebration. Highly recommend Princess Lawn for any event!`,
      rating: 4,
      image: "/api/placeholder/150/150",
    },
    {
      id: 5,
      name: "Krutika Ambarte",
      quote:
        `Thank you, Princess Lawn, for making our event unforgettable! The stunning venue, combined with exceptional decor and in-house services, created the perfect atmosphere. The staff was professional, and the facilities were top-notch.
         We had an amazing experience and would highly recommend Princess Lawn for any occasion!`,
      rating: 4,
      image: "/api/placeholder/150/150",
    },
  ];

export default testimonials