import foodCourt_1 from "../assets/images/gallery/FoodCourt/FoodCourt_1.JPG";
import mukhwas_1 from "../assets/images/gallery/FoodCourt/Mukhwas_1.JPG";
import plateCounter_1 from "../assets/images/gallery/FoodCourt/PlateCounter_1.JPG";
import sweetCounter_1 from "../assets/images/gallery/FoodCourt/SweetCounter_1.JPG";
import popcorn_coffee_1 from "../assets/images/gallery/FoodCourt/Popcorn_Coffee_1.JPG";
import haldi_1 from "../assets/images/gallery/Haldi/Haldi_1.JPG";
import haldi_2 from "../assets/images/gallery/Haldi/Haldi_2.JPG";
import haldi_3 from "../assets/images/gallery/Haldi/Haldi_3.JPG";
import entrance from "../assets/images/gallery/GrandEntry/Entrance.JPG";
import car_1 from "../assets/images/gallery/GrandEntry/Car_1.JPG";
import main_entrance_2 from "../assets/images/gallery/GrandEntry/MainEntrance_2.JPG";
import main_entrance_1 from "../assets/images/gallery/GrandEntry/MainEntrance_1.JPG";
import grand_entry_2 from "../assets/images/gallery/GrandEntry/GrandEntry_2.JPG";
import mainStage_decoration_1 from "../assets/images/gallery/LawnDecoration/MainStage_Decoration_1.jpg";
import mainStage_decoration_2 from "../assets/images/gallery/LawnDecoration/MainStage_Decoration_2.JPG";
import mainStage_decoration_3 from "../assets/images/gallery/LawnDecoration/MainStage_Decoration_3.JPG";
import mainStage_decoration_4 from "../assets/images/gallery/LawnDecoration/MainStage_Decoration_4.JPG";
import mainStage_decoration_5 from "../assets/images/gallery/LawnDecoration/MainStage_Decoration_5.JPG";
import seating_arrangement_1 from "../assets/images/gallery/LawnDecoration/SeatingArrangement_1.jpg";
import seating_arrangement_2 from "../assets/images/gallery/LawnDecoration/SeatingArrangement_2.JPG";
import foodcort_1 from "../assets/videos/Foodcort_1.MP4";
// import mainEntrance_1 from "../assets/videos/MainEntrance_1.MP4";
// import grandEntrance_Bday_1 from "../assets/videos/Grandentry_BdayKid_1.MP4";
import seatingArrangement_4 from "../assets/videos/SeatingArrangement_4.MP4";
import pathwayBallonDecoration_2 from "../assets/videos/PathwayBalloonDecoration_2.MP4";
import stupa_1 from "../assets/videos/Stupa_1.MP4";
// import bdaymainStagedecoration_6 from "../assets/videos/BdaymainStagedecoration_6.MP4";

export const gallerySectionLawn = [
    {
      sectionTitle: "Lawn Decoration",
      images: [
        mainStage_decoration_1,mainStage_decoration_2,mainStage_decoration_3,mainStage_decoration_4,mainStage_decoration_5,seating_arrangement_1,seating_arrangement_2
      ],
    
    },
  ];
  export const gallerySectionHaldi = [
    {
      sectionTitle: "Haldi and Sangeet Decoration",
      images: [
        haldi_1,haldi_2,haldi_3
      ],  
    
    },
  ];
  export const gallerySectionBirthday = [
    {
      sectionTitle: "Birthday Decoration",
      images: [
         
      ]   
    },
  ];
  export const gallerySectionMarriage = [
    {
      sectionTitle: "Marriage Decoration",
      images: [
          entrance,entrance,entrance,entrance,entrance,entrance,entrance,entrance,entrance,entrance,entrance,entrance,entrance
      ]
    
    },
  ];
  export const gallerySectionFCA = [
    {
      sectionTitle: "Food Court Area",
      images: [
        foodCourt_1,mukhwas_1,plateCounter_1,sweetCounter_1,popcorn_coffee_1
      ]
    
    },
  ];
  export const gallerySectionGrandEntry = [
    {
      sectionTitle: "Grand Entry",
      images: [
        main_entrance_1,main_entrance_2,entrance,car_1,grand_entry_2,
      ]
   
    },    
  ];

  export const gallerySectionVideo = [
    {
      sectionTitle: "Visual Stories",
      images: [
        foodcort_1,seatingArrangement_4,pathwayBallonDecoration_2,stupa_1
      ]
   
    },    
  ];

  
  