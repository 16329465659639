import { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";
import { inHouseServiceImages } from "../utilities/images.js";
import "../styles/InhouseServices.css";

const InhouseServices = () => {
  const [width, setWidth] = useState(0);
  const carousel = useRef();

  useEffect(() => {
    setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
  }, []);
  return (
    <div>
      <motion.div
        ref={carousel}
        className="carousel"
        whileTap={{ cursor: "grabbing" }}
      >
        <motion.div
          drag="x"
          dragConstraints={{ right: 0, left: -width }}
          className="inner-carousel"
        >
          {inHouseServiceImages.map((image) => {
            return (
              <motion.div className="item" key={image}>
                <img src={image} atl="image" />
              </motion.div>
            );
          })}
        </motion.div>
      </motion.div>
    </div>
  );
};

export default InhouseServices;
